import { useState, useEffect } from "react"

import Calendly from "https://framer.com/m/Calendly-qFmu.js@ZVFGE0CIYem9dXbVNw5j"

function isCalendlyEvent(e) {
    return (
        e.origin === "https://calendly.com" &&
        e.data.event &&
        e.data.event.indexOf("calendly.") === 0
    )
}

function trackAdsConversion(callback) {
    window?.gtag("event", "conversion", {
        send_to: "AW-10848430869/-epECKyjnd0YEJXW97Qo",
        value: 0.0,
        currency: "USD",
        event_callback: callback,
    })
}

export default function CustomCalendlyWidget(props) {
    const [calendar, setCalendar] = useState(null)

    useEffect(() => {
        // https://calendly.com/start-divorce-com/free-15-min-call
        // calendar name from Calendly
        // const calendarName =
        //     "d/366-32z-63z/schedule-your-free-15-minute-consultation"
        const calendarName = "start-divorce-com/free-15-min-call"
        const serverName =
            window.location.host === "divorce.com" ? "production" : "staging"
        const experiment =
            "Dcom Free 15-min Call Experiment:dcom_lead_created_with_calendly"
        const utms = `utm_campaign=${serverName}&utm_medium=${experiment}`
        setCalendar(`${calendarName}?${utms}`)
    }, [])

    const handleCalendlyEvent = (e) => {
        if (isCalendlyEvent(e)) {
            /* Example to get the name of the event */
            // console.log("Event name:", e.data.event)
            // /* Example to get the payload of the event */
            // console.log("Event details:", e.data.payload)
            const eventName = e.data.event
            const calendlyPayload = e.data.payload

            if (eventName === "calendly.event_scheduled") {
                window?.posthog?.capture("Calendly Event Scheduled", {
                    ...(calendlyPayload || {}),
                })
                trackAdsConversion(() => {
                    console.log("conversion tracked")
                })
            }
        }
    }

    useEffect(() => {
        window.addEventListener("message", handleCalendlyEvent)
        return () => {
            window.removeEventListener("message", handleCalendlyEvent)
        }
    }, [])

    return (
        calendar && (
            <Calendly
                {...props}
                calendar={calendar}
                hideLandingPageDetails={false}
            />
        )
    )
}
